import React from 'react';
import { Link } from 'gatsby';

import Layout from '../containers/Layout';

const Index = () => {
  return (
    <Layout title="404">
      Page is not exists, please, proceed to <Link to="/">Home Page</Link>
    </Layout>
  );
}

export default Index;